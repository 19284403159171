<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <TInputSimpleSearch placeholder="Search sku" :value.sync="search" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in boxes_available"
            :key="`${index}${box.id}`"
            :color="box_id_selected == box.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { box_id: box.id })"
          >
            <SCardBox :box="box" />
          </CListGroupItem>
          <CListGroupItem v-if="!boxes_available.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="boxes_available_loading">
            <TSpinner :loading="boxes_available_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.boxes_freedom" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="box_id_selected">
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center">
                <TMessage content="Split boxes" bold>
                  <template #suffix> {{ box_id_selected || "..." }} </template>
                </TMessage>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="12" class="mb-3">
                  <TInputNumber
                    label="Quantity to split"
                    size="lg"
                    :value.sync="quantityDecouple"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter class="d-flex">
              <TButton
                color="success"
                :messageOptions="{ bold: true, noTranslate: true }"
                :options="{ disabled: !quantityDecouple || loading }"
                icon="cil-data-transfer-down"
                class="mx-auto"
                @click="decouple"
              >
                <template #content>
                  <TMessage content="Split boxes" bold class="ml-2" />
                </template>
              </TButton>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol col="12">
          <CCard v-if="box_id_selected && boxes_ref.length">
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage content="SKUs separate from" bold>
                  <template #suffix>
                    <span class="ml-1">{{ box_id_selected || "..." }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="boxes_ref_loading" />
              </div>
            </CCardHeader>
            <CCardBody>
              <TMessageNoData content="No SKU" v-if="!boxes_ref.length" />
              <CRow v-else class="d-flex align-items-stretch">
                <SCardSku
                  v-for="box in boxes_ref"
                  :key="box.id"
                  :box="box"
                  class="my-3 mx-3"
                />
              </CRow>
              <SButtonLoadmore store="warehouse.boxes_ref_decouple" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import searchable from "../../mixins/searchable";
export default {
  mixins: [searchable],
  data() {
    return {
      quantityDecouple: 0,
    };
  },
  watch: {
    box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.boxes_freedom.detail.fetch", id);
          this.fetchBoxesRef();
          this.quantityDecouple = 0;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      boxes_available: "warehouse.boxes_freedom.list",
      boxes_available_loading: "warehouse.boxes_freedom.loading",
      boxes_ref: "warehouse.boxes_ref_decouple.list",
      boxes_ref_loading: "warehouse.boxes_ref_decouple.loading",
      loading: "warehouse.decouple_box.loading",
    }),
    box_id_selected() {
      return this.$route.query.box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.boxes_freedom.detail"];
    },
  },
  methods: {
    fetchBoxesRef() {
      this.$store.dispatch("warehouse.boxes_ref_decouple.apply-query", {
        sort: "-created_at",
        "filter[box_ref_id]": this.box_id_selected,
      });
    },
    triggerSearch(value) {
      if (value || value == "") {
        this.$store.dispatch("warehouse.boxes_freedom.apply-query", {
          "filter[id]": value,
        });
      } else
        this.$store.dispatch("warehouse.boxes_freedom.fetch.if-first-time");
    },
    decouple() {
      const data = {
        duplicate: this.quantityDecouple,
        box_id: this.box_id_selected,
      };
      this.$store
        .dispatch("warehouse.decouple_box.create", data)
        .then((data) => {
          this.result = data.decoupled_box;
          this.quantityDecouple = 0;
          setTimeout(() => {
            this.triggerSearch();
            this.fetchBoxesRef();
          }, 1000);
        });
    },
  },
};
</script>
